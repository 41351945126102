import Router from 'next/router';

function redirect({ context, target, code = 301 }) {
  if (context && context.res) {
    context.res.writeHead(code, { Location: target });
    context.res.end();
  } else {
    Router.replace(target);
  }
}

export { redirect };

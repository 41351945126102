import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

import { CreateUserForm } from './CreateUserForm';

class CreateUserModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false
    };
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  onClose = () => {
    this.setState({ modalVisible: false });
  };

  manageResponse = (status) => {
    if (status === 'OK') {
      this.onClose();
      this.props.pushAlert('User Successfully created!', true, 'CreateAccount');
    } else {
      console.log('ERROR CREATING USER');
    }
  };

  render() {
    return (
      <React.Fragment>
        <Button size='large' style={{marginLeft: '5px'}} onClick={this.showModal} type="primary">
          Create User
        </Button>
        <StyledModal
          visible={this.state.modalVisible}
          footer={null}
          onCancel={this.onClose}
          closable={true}
          width="40%"
        >
          <CreateUserForm userId={this.props.userId} manageResponse={this.manageResponse}/>
        </StyledModal>
      </React.Fragment>
    );
  }
}

const StyledModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    padding: 0;
  }
`;

export { CreateUserModal };

const nextRoutes = require('next-routes');

const routes = nextRoutes();

routes
  .add({
    name: 'home',
    page: 'index',
    pattern: '/'
  })
  .add({
    name: 'login',
    page: 'login',
    pattern: '/login'
  })
  .add({
    name: 'login-callback',
    page: 'login/callback',
    pattern: '/login/callback'
  })
  .add({
    name: 'logout',
    page: 'logout',
    pattern: '/logout'
  })
  .add({
    name: 'users',
    page: 'users',
    pattern: '/users'
  })
  .add({
    name: 'roles',
    page: 'roles',
    pattern: '/roles'
  })
  .add({
    name: 'test',
    page: 'test',
    pattern: '/test'
  });

module.exports = routes;

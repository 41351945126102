
import { getConfig } from 'config';
import React, { Component } from 'react';
import { Spin } from 'antd';
import urlJoin from 'url-join';

import { redirect } from '../../src/utils/redirect';
import { authService } from '../services/auth';

const { ACG_BERNIE_DOMAIN } = getConfig();

const getDisplayName = ComposedComponent => {
  return `Authenticated(${ComposedComponent.displayName ||
    ComposedComponent.name ||
    'Unknown'})`;
};

const withAuthorization = ComposedAuthComponent => {
  return class Authorized extends Component {
    static displayName = getDisplayName(ComposedAuthComponent);

    state = {
      isAuthorized: false
    };

    async componentDidMount() {
      const currentUrl = window.location.href;
      return authService.authorize({ allowRoles: ['ADMIN'] })
        .then(res => {
          const { isAuthenticated, isAuthorized } = res || {};

          if (!isAuthenticated) {
            const loginUrl = urlJoin(ACG_BERNIE_DOMAIN, 'login', `?redirect_to=${encodeURIComponent(currentUrl)}`);
            return window.location.replace(loginUrl);
          }

          if (!isAuthorized) {
            return redirect({ target: '/oops' });
          }

          this.setState({
            isAuthorized: true
          });
        });
    }

    render() {
      if (!this.state.isAuthorized) {
        return (
          <div style={{ minHeight: '100vh', position: 'relative' }}>
            <Spin style={{ position: 'absolute', left: '50%', top: '50% '}} size="large" />
          </div>
        );
      }

      return (<ComposedAuthComponent serverState={this.props} />);
    }
  };
};

export {
  withAuthorization
};

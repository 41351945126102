import { getConfig } from 'config';

const { CLOUDINARY_ACCOUNT, CLOUDINARY_BASE_URL } = getConfig();

const attributeMap = {
  width: {
    name: 'w',
    defaultValue: 200
  },
  height: {
    name: 'h',
    defaultValue: 200
  },
  quality: {
    name: 'q',
    defaultValue: 'auto'
  },
  focus: {
    name: 'q',
    defaultValue: 'auto'
  }
};

const ImageOptimizer = ({
  children,
  ...options
}) => {

  const transforms = Object.keys(attributeMap).reduce((accum, key) => {
    const value = options[key] || attributeMap[key].defaultValue;
    accum.push(`${attributeMap[key].name}_${value}`);
    return accum;
  }, [])
    .join(',');


  const buildOptimizedSrc = src => {
    if (!src) {
      return src;
    }

    return [`${CLOUDINARY_BASE_URL}/${CLOUDINARY_ACCOUNT}/image/fetch`]
      .concat(transforms)
      .concat(encodeURIComponent(src))
      .join('/');
  };

  return children({ buildOptimizedSrc });
};

export { ImageOptimizer };
const { rgba } = require('polished');

export const brandFont = 'GT Walsheim';

export const colour = {
  blue: '#221C71',
  blueDark: '#070247',
  blueLight: '#3366FF',
  green: '#08C472',
  purple: '#843EED',
  orange: '#FC8918',
  red: '#F40064',
  gray100: '#F2F5FC',
  gray400: '#B6BFD1',
  gray600: '#6C6C94',
  gray900: '#3B4966',
  hover: '#F9FAFD'
};

export const boxShadow = {
  standard: `0 12px 24px 0 ${rgba(colour.blueDark, 0.06)}`,
  subtle: `0 3px 15px 0 ${rgba(colour.blueDark, 0.06)}`,
  inset: `inset 0 1px 4px 0 ${rgba(colour.blueDark, 0.06)}`
};

export const borderRadius = '8px';

export default {
  brandFont,
  colour,
  boxShadow,
  borderRadius
};

import { StyledAlert } from './StyledAlert';

export const AlertContainer = ({ message, success, error }) => {
  return (
    <>
      <div style={{ margin: '0.5em 0' }}>
        {success && <StyledAlert message={message} type="success" showIcon />}
        {!success && message && < StyledAlert message={message} type="error" showIcon />}
        {error && <StyledAlert message={error.message} type="error" showIcon />}
      </div>
    </>
  );
};
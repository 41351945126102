import { Form, Button, Input } from 'antd';
import gql from 'graphql-tag';
import { darken, rgba } from 'polished';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';

import { boxShadow, colour } from '../../styles/variables';
import { useState } from 'react';

const CREATE_USER_MUTATION = gql`
  mutation createAccount($user: CreateAccountInput!) {
    createAccount(
      user: $user
    ) {
      status
    }
  }
`;

const CreateUser = ({ form: {
  getFieldDecorator,
  getFieldValue,
  getFieldsValue,
  isFieldTouched,
  validateFields
}, manageResponse }) => {
  const [invalidForm, setInvalidForm] = useState(true);

  const passwordRules = [
    {
      validator: (rule, value) => {
        if (!value || !(/[a-z]{1}/g.test(value))) {
          return Promise.reject(new Error('One lowercase letter'));
        } else {
          return Promise.resolve();
        }
      },
      message: 'One lowercase letter'
    },
    {
      validator: (rule, value) => {
        if (!value || !(/\d{1}/g.test(value))) {
          return Promise.reject(new Error('One number'));
        } else {
          return Promise.resolve();
        }
      },
      message: 'One number'
    },
    {
      validator: (rule, value) => {
        if (!value || !(/[A-Z]{1}/g.test(value))) {
          return Promise.reject(new Error('One uppercase letter'));
        } else {
          return Promise.resolve();
        }
      },
      message: 'One uppercase letter'
    },
    {
      validator: (rule, value) => {
        if (!value || value.length < 8) {
          return Promise.reject(new Error('8 characters minimum'));
        } else {
          return Promise.resolve();
        }
      },
      message: '8 characters minimum'
    }
  ];

  const validateForm = () => {
    setTimeout(async () => {
      const allFields = getFieldsValue();
      const keys = Object.keys(allFields);

      const checkField = (key) => new Promise((resolve) => {
        validateFields([key], (err) => {
          if (err) return resolve(false);
          return resolve(true);
        });
      });

      const fieldChecks = [];
      for (const key of keys) {
        const isTouched = isFieldTouched(key);
        if (!isTouched) {
          fieldChecks.push(false);
        } else {
          fieldChecks.push(await checkField(key));
        }
      }

      setInvalidForm(fieldChecks.includes(false));
    }, 50);
  };

  return (
    <Mutation mutation={CREATE_USER_MUTATION}>
      {(createAccount, { loading, error }) => {
        return (
          <ModalContainer>
            <StyledForm
              layout="vertical"
              hideRequiredMark
              onSubmit={e => {
                e.preventDefault();
                const email = getFieldValue('email');
                createAccount({
                  variables: {
                    user: {
                      email,
                      password: getFieldValue('password'),
                      username: email.replace('@', '_'),
                      connection: 'Username-Password-Authentication',
                      user_metadata: JSON.stringify({invitationCode:'Identity Created User'})
                    }
                  }
                }).then((data) => {
                  const status = data.data?.createAccount?.status;
                  manageResponse(status);
                });
              }}
            >
              <Form.Item
                label="Email"
              >
                {getFieldDecorator('email', {
                  rules: [{ required: true }]
                })(<Input autoFocus type="text" />)}
              </Form.Item>
              <Form.Item
                label="Password"
                onChange={validateForm}
              >
                {getFieldDecorator('password', {
                  rules: passwordRules
                })(<Input autoFocus type="password" />)}
              </Form.Item>
              {error && <MutationError>Error creating user</MutationError>}
              <UpdateButton disabled={invalidForm} htmlType="submit" loading={loading}>
                {loading ? null : 'Create New User'}
              </UpdateButton>
            </StyledForm>
          </ModalContainer>
        );
      }}
    </Mutation>
  );
};

const ModalContainer = styled.div`
  padding: 30px 18px;
  width: 100%;

  .ant-form {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    padding: 0 0 6px;
  }

  .ant-row {
    margin: 0;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;

  label {
    color: ${colour.gray900};
    font-weight: 600;
  }

  input {
    position: relative;
    left: -2px;
    min-width: 300px;
    box-shadow: ${boxShadow.inset};

    &:hover,
    &:focus {
      border-color: ${rgba(0, 0, 0, 0.35)};
    }
  }
`;

const MutationError = styled.div`
  color: ${colour.red};
  margin: 4px 0 12px;
`;

const UpdateButton = styled(Button)`
  padding: 0 18px;
  color: #fff;
  background-color: ${colour.blueLight};
  border: 0;

  &:focus {
    color: #fff;
    background-color: ${colour.blueLight};    
  }

  &:hover {
    color: #fff;
    background-color: ${darken(0.05, colour.blueLight)};
  }
`;

const CreateUserForm = Form.create()(CreateUser);

export { CreateUserForm };

import get from 'lodash.get';
import React from 'react';
import { getConfig } from 'config';
import urlJoin from 'url-join';
import { Layout, Icon } from 'antd';
import { withRouter } from 'next/router';
import { Router } from 'routes';

import { Footer } from './Footer';
import { colour } from '../styles/variables';
import { redirect } from '../../src/utils/redirect';
import styled, { css } from 'styled-components';

const { ACG_BERNIE_DOMAIN, ACG_ENV } = getConfig();

const { Header } = Layout;

const linkedMenuKeys = {
  '/user/[id]': '/users',
  '/roles': '/roles',
  '/users': '/users'
};

class AdminLayout extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  logout() {
    return redirect({ target: '/logout' });
  }

  render() {
    const { router } = this.props;
    const pathname = get(router, 'pathname', '');
    const currentPath = linkedMenuKeys[pathname];

    console.log('pathname: ', pathname);
    console.log('currentPath: ', currentPath);

    return (
      <Layout>
        <StyledHeader>
          <LHS>
            <StyledLI name="logo">
              <Logo
                onClick={() => Router.pushRoute('/users')}
                src="/public/images/logos/logo.png"
                alt=""
              />
            </StyledLI>
            <StyledLI name="/users" pathname={currentPath} >
              <a href={urlJoin(ACG_BERNIE_DOMAIN, 'users')}>Users</a>
            </StyledLI>
            <StyledLI name="/organizations" pathname={currentPath}>
              <a href={urlJoin(ACG_BERNIE_DOMAIN, 'organizations')}>Organizations</a>
            </StyledLI>
            <StyledLI name="/roles" pathname={currentPath}>
              <a href={urlJoin(ACG_BERNIE_DOMAIN, 'roles')}>Site Roles</a>
            </StyledLI>
            {
              ACG_ENV !== 'production' && (
                <StyledLI name="/skills-assessments" pathname={currentPath}>
                  <a href={urlJoin(ACG_BERNIE_DOMAIN, 'skills-assessments')}>Skills Assessments</a>
                </StyledLI>
              )
            }
            <StyledLI name="/logout">
              <a onClick={() => this.logout()}>Logout</a>
            </StyledLI>
          </LHS>
          <RHS>
            <HelpLink href="https://help.acloud.guru/" target="_blank">
              <Icon type="question-circle-o" />
            </HelpLink>
          </RHS>
        </StyledHeader>
        {this.props.masthead}
        <PageBody>{this.props.children}</PageBody>
        <Footer />
      </Layout>
    );
  }
}

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: ${colour.blue};
  padding: 0 60px 0 60px;

  li {
    padding: 0 0.75rem;
    color: ${colour.gray400};
    border: 0!important;

    &:first-child {
      padding-left: 0;
    }

    &::selection {
      border: 0;
    }
  }
`;

const StyledLI = styled.li`
  ${({ name, pathname }) => pathname === name &&
    css`
      && a {
        color: #fff;
      }
    `
  }
`;

const LHS = styled.ul`
  display: flex;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: ${colour.blue};

  list-style-type: none;

  a {
      color: ${colour.gray400};
      transition: all 0.3s;

      &:hover {
        color: #fff;
      }
    }
`;

const RHS = styled.div`
  border: 0;
  background-color: ${colour.blue};

  .ant-menu-item {
    a {
      color: ${colour.gray400};
      transition: all 0.3s;

      &:hover {
        color: #fff;
      }
    }
  }

  .ant-menu-item-selected {
    a {
      color: #fff;
    }
  }

  li {
    padding: 0 0.25rem;
  }
`;

const Logo = styled.img`
  position: relative;
  top: -2px;
`;

const HelpLink = styled.a`
  &:hover {
    .anticon {
      color: #fff;
    }
  }

  .anticon {
    transition: all 0.3s;
    position: relative;
    top: 0.25rem;
    font-size: 1.25rem;
    font-weight: 200;
    margin: 0 10px;
    color: ${colour.gray400};
  }
`;

const PageBody = styled.div`
  padding: 24px 60px 22px 60px;
  flex: 1;
`;

export default withRouter(AdminLayout);

import React from 'react';
import AdminLayout from '../../src/layouts/AdminLayout';
import { withAuthorization } from '../../src/lib/withAuthorization';
import { Input, List, Avatar  } from 'antd';
import { withApollo } from 'react-apollo';
import { ImageOptimizer } from '../../src/components/image/ImageOptimizer';
import gql from 'graphql-tag';
import get from 'lodash.get';
import styled from 'styled-components';

import { CreateUserModal } from '../../src/components/user/CreateUserModal';
import { AlertContainer } from '../../src/components/user/AlertContainer';

const Search = Input.Search;

const AUTH0_QUERY = gql`
  query ACG_searchAuth0Users($query: String!, $page: Int, $pageSize: Int) {
    ACG_searchAuth0Users(query: $query, page: $page, pageSize: $pageSize) {
      userIdentities {
        rawId
        email
        name
        picture
      }
    }
  }
`;

const PS_ID_QUERY = gql`
  query userIdentities($pluralsightId: String!) {
    userIdentities(pluralsightId: $pluralsightId) {
      id
      picture
      name
      email
    }
  }
`;

const PS_HANDLE_QUERY = gql`
  query getByPsHandle($pluralsightHandle: String!) {
    getByPsHandle(pluralsightHandle: $pluralsightHandle) {
      id
      picture
      name
      email
    }
  }
`;

const UUID_REXEP = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

class Users extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
      dirty: false,
      alertContainers: []
    };

    this.timeOuts = [];

    this.pushAlert = this.pushAlert.bind(this);
    this.popAlert = this.popAlert.bind(this);
  }

  pushAlert(message, success, key) {
    this.setState({
      alertContainers: [
        ...this.state.alertContainers,
        <AlertContainer message={message} success={success} key={key} />
      ]
    });
    this.timeOutAlert();
  }

  popAlert() {
    this.state.alertContainers.pop();
    this.setState({
      alertContainers: [this.state.alertContainers]
    });
  }

  timeOutAlert() {
    this.timeOuts.push(setTimeout(() => this.popAlert(), 3000));
  }

  preventTimeOutMemLeak() {
    this.timeOuts.forEach((timeout) => clearTimeout(timeout));
    this.timeOuts = [];
  }

  async getUsersByPsIdOrHandle(queryType, variables, responseObject) {
    await this.props.client
      .query({ query: queryType, variables })
      .then(res => {
        const response = get(res, responseObject);
        if (response && response.length) {
          const userIdentities = response.map((user) => { 
            return { ...user, rawId: user.id };
          });
          this.setUsers(userIdentities);
        } else {
          this.setState({ users: [] });
        }
      });
  }

  setUsers(userIdentities) {
    // sometimes empty values come back from response
    // grab only users with valid values
    const users = userIdentities.filter((user) => user.email);
    this.setState({ users, loading: false });
  }

  async searchUsers(query) {
    if (!query) return [];

    if (query.match(UUID_REXEP) && query.match(UUID_REXEP).length) {
      // Check if we can find users with psId
      this.setState({ loading: true, dirty: true });
      await this.getUsersByPsIdOrHandle(PS_ID_QUERY, { pluralsightId: query }, 'data.userIdentities');

      // If there's no users then check by handle
      if (!this.state.users.length) {
        await this.getUsersByPsIdOrHandle(PS_HANDLE_QUERY, { pluralsightHandle: query }, 'data.getByPsHandle');
      }
      this.setState({ loading: false });
    } else {
      this.setState({ loading: true, dirty: true });
  
      const variables = {
        query,
        page: 0,
        pageSize: 100
      };
  
      return this.props.client
        .query({ query: AUTH0_QUERY, variables })
        .then(res => {
          let userIdentities =
            get(res, 'data.ACG_searchAuth0Users.userIdentities') || [];
          this.setUsers(userIdentities);
        })
        .catch(console.error);
    }
  }

  generateList() {
    const { users, loading, dirty } = this.state;

    if (users.length || loading || dirty) {
      return (
        <StyledList
          dataSource={this.state.users}
          loading={this.state.loading}
          locale={{ emptyText: 'No users found' }}
          pagination={this.state.users.length ? { pageSize: 10 } : false}
          renderItem={item => (
            <List.Item key={item.rawId}>
              <a href={`/user/${item.rawId}`}>
                <List.Item.Meta
                  avatar={
                    <ImageOptimizer width={32} height={32}>
                      {({ buildOptimizedSrc }) => (
                        <Avatar src={buildOptimizedSrc(item.picture)} />
                      )}
                    </ImageOptimizer>
                  }
                  title={item.name}
                  description={item.email}
                />
              </a>
            </List.Item>
          )}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <AdminLayout>
        <h1>Users Search</h1>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Search
            placeholder="Search users"
            size="large"
            onSearch={value => this.searchUsers(value)}
            enterButton
          />
          <CreateUserModal pushAlert={this.pushAlert}></CreateUserModal>
        </div>
        {this.state.alertContainers}
        { this.generateList() }
      </AdminLayout>
    );
  }
}

const StyledList = styled(List)`
  background: #fff;
  border-radius: 4px;
  padding: 0 1em;
  margin-top: 1em;
  box-shadow: 0 5px 10px rgba(50,50,93,.1);

  &:empty {
    display: none;
  }

  .ant-pagination {
    padding-bottom: 2em;
  }
`;

export default withAuthorization(withApollo(Users));